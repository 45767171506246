.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  align-items: center;
}

.counter {
  height: 535px;
}
.heading {
  height: 140px;
  margin-bottom: 20px;
}
.text {
  height: 68px;
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .content {
    order: -1;
  }
  .counter {
    height: 424px;
  }
  .heading {
    height: 118px;
  }
  .text {
    height: 75px;
  }
}
