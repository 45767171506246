.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  align-items: center;
}
.image {
  height: 577px;
}
.title {
  height: 95px;
  margin-bottom: 15px;
}
.sub_title {
  height: 170px;
  margin-bottom: 20px;
}
.button {
  height: 52px;
  width: 117px;
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .image {
    display: none;
  }
}
