.tab {
  display: flex;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  gap: 40px;
  margin-top: 30px;
  @media (max-width: 900px) {
    max-width: 80%;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  align-items: center;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
