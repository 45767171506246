.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 30px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
.title {
  height: 200px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    height: 120px;
  }
}
.sub_title {
  height: 70px;
  margin-bottom: 16px;
}
.search {
  height: 60px;
  @media (max-width: 600px) {
    height: 200px;
  }
}
.features {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 25px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
