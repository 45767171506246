.title {
  height: 78px;
  margin-bottom: 30px;
  max-width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.press {
  height: 90px;
  margin-bottom: 1px;
}

@media (max-width: 600px) {
  .press {
    height: 230px;
  }
}
