.title {
  height: 95px;
  margin-bottom: 15px;
}
.sub_title {
  height: 68px;
  margin-bottom: 30px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
}

@media (max-width: 600px) {
  .title {
    height: 78px;
  }
  .sub_title {
    height: 100px;
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
